/* this file is transformed by vux-loader */
/* eslint-disable */
import { keepTwoDecimalFull } from "../../../assets/app.js";
export default {
  props: {
    wpInfo: {
      default: ""
    }
  },
  data() {
    return {
      datas: {},
      showWpflag: false,
      yzmcList: ["一般物品", "固定资产", "服务类"]
    };
  },
  computed: {
    total: function () {
      let zj = keepTwoDecimalFull(this.wpInfo.sqsl * this.wpInfo.price);
      return zj;
    }
  },
  methods: {
    onWpflag(value) {
      this.wpInfo.wpzt = value;
      this.showWpflag = false;
    },
    onProps() {
      this.datas = this.wpInfo;
      this.datas._state = "added";
      this.datas.total = this.total;
      this.$emit("wpListInfo", this.datas);
    }
  }
};