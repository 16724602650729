/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import MyTable from "../../common/ypcg/MyTable";
import AddwpInfo from "../../common/ypcg/AddwpInfo";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, unique, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqYpcg",
  components: {
    LCFQheader,
    MyTable,
    AddwpInfo
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "用品采购",
      showDataCG: false,
      showsyqck: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      resultFa: [],
      tremListId: [],
      cgwpDatas: [],
      wpInfoData: {},
      datas: {
        cgsqdbh: "",
        cgsqr: "",
        cgsqrbm: ""
      },
      cgsqrT: new Date()
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
      });
      let urlBh = _baseUrl + "/sys/core/sysSeqId/genNo_cgsqd_bh.do";
      this.$ajax.post(urlBh).then(res => {
        // let cgsqdbh = res.data;
        this.datas.cgsqdbh = res.data;
      });
      let userId = {
        PARTY2_: RxUtil.getCache("userID")
      };
      let formData = new FormData();
      formData.append("params", JSON.stringify(userId));
      let urlBm = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_hqbm.do";
      this.$ajax.post(urlBm, formData).then(res => {
        this.datas.cgsqrbm = res.data.data[0].NAME_;
      });
    },
    async cgORxj() {
      let busad = this.$route.params.busad;
      let instId = this.$route.params.instId;
      console.log('instId = ' + instId);
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids=" + busad;
        this.$ajax.post(url).then(res => {
          console.log(res.data);
          this.datas = res.data;
        });
        let urlGet = _baseUrl + `/customizeProc/supIosTab/supIosTab/findByCgid.do?cgid=${busad}&pageIndex=0&pageSize=100000&sortField=&sortOrder`;
        this.$ajax.post(urlGet).then(resGet => {
          console.log(resGet.data.data);
          this.cgwpDatas = resGet.data.data;
        });
      } else {}
      this.getUser();
    },
    //获取用户信息
    getUser() {
      this.datas.cgsqr = RxUtil.getCache("userName");
    },
    onConfirmCG(timestamp) {
      this.datas.cgrq = format(timestamp);
      this.cgsqrT = formatHMT(timestamp);
      this.showDataCG = false;
    },
    showList() {
      this.tremListId = this.cgwpDatas;
      this.showListpage = true;
    },
    cgwpList(list) {
      this.cgwpDatas = list;
      this.showListpage = false;
    },
    //查看具体信息
    goodsInfo(item) {
      console.log(item);
      this.showGinfo = true;
      this.wpInfoData = item;
    },
    //添加信息
    wpListInfo(item) {
      this.cgwpDatas.map(datai => {
        if (datai.pkId == item.pkId) {
          datai = item;
        }
      });
      this.showGinfo = false;
    },
    //删除
    delectWP() {
      for (let i = 0; i < this.resultFa.length; i++) {
        for (let y = 0; y < this.cgwpDatas.length; y++) {
          if (this.resultFa[i] == this.cgwpDatas[y].pkId) {
            this.cgwpDatas.splice(this.cgwpDatas[y], 1);
          }
        }
      }
      this.$refs.checkboxGroup.toggleAll(false);
    },
    upDatas() {
      if (this.cgwpDatas.sqsl != "" && this.cgwpDatas.price != "" && this.datas.cgrq != "" && this.datas.cgsy != "") {
        let sumtotal = 0;
        for (let i = 0; i < this.cgwpDatas.length; i++) {
          sumtotal += Number.parseFloat(this.cgwpDatas[i].total).toFixed(2);
          this.cgwpDatas[i].createTime = formatHMT(this.cgwpDatas[i].createTime);
          //this.cgwpDatas[i].updateTime = formatHMT(this.cgwpDatas[i].updateTime);
          this.cgwpDatas[i].basicid = this.cgwpDatas[i].pkId;
          this.cgwpDatas[i]._id = i + 1;
          this.cgwpDatas[i]._uid = i + 1;
        }
        var jsonDataObj = {
          // bos:[{
          //   boDefId:this.boDefId,
          //   formKey:this.formKey,
          //   data:{
          id: "",
          cgsqdbh: this.datas.cgsqdbh,
          cgsqr: this.datas.cgsqr,
          cgsqrbm: this.datas.cgsqrbm,
          cgrq: this.datas.cgrq,
          cgsy: this.datas.cgsy,
          bz: this.datas.bz,
          // sumtotal:sumtotal,
          sumtotal: "",
          SUB_datagrid1: this.cgwpDatas
          //   }
          // }]
        };

        var jsonData = JSON.stringify(jsonDataObj);
        var params = new FormData();
        params.append("solId", this.solId);
        params.append("bpmInstId", "");
        params.append("jsonData", jsonData);
        return params;
      } else {
        this.errorToast("物品信息未完善！");
      }
    },
    onSubmit() {
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onPreservation() {
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        }
      });
    },
    onLct() {
      console.log(this.actDefId);
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};